@import 'utils';

.rtrCatIconSmall {
  width: 30pt;
  height: auto;
}

.rtrCatIconBig,
.rtrCatIconSmall {
  display: block;
  margin: 0 auto;
  background-color: white;
  border-radius: 50%;
}

.rtrCatIconBig {
  @include desktop() {
    width: 100px;
    height: 100px;
  }

  @include mobile() {
    width: num-em(150);
    height: num-em(150);
  }
}