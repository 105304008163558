@import 'utils';

#rtrQuest, #rtrQuestResult {
  .rtrContent {
    @include desktop() {
      background-image: url("img/sketch-left-1.png"), url("img/sketch-right-1.png");
      background-position: left 0, right 25px;
      background-size: 96px auto;
      background-repeat: no-repeat;
    }
  }

  // Quest block
  .rtrQuestBlock {
    background-color: #ededed;

    button {
      width: 100%;
    }

    button {
      width: 100%;
    }

    @include desktop() {
      width: 512px;
      padding: 10px 30px;
      margin: 0 auto 10px auto;
      box-sizing: border-box;

      h1 {
        margin: 10px 0;
        display: block;
        font-size: 30px;
      }

      h2 {
        font-size: 21px;
        margin-bottom: 5px;
      }
    }

    @include mobile() {
      padding: num-em(20);
      margin-bottom: 10px;

      h2 {
        font-size: num-em(42);
        margin-bottom: num-em(10);
      }
    }
  }

  // Video
  .rtrBigVideo {
    color: white;
    background-color: black;
    width: 452px;
    height: 255px;

    @include mobile() {
      width: num-em(660);
      height: num-em(370);
    }

    &::before {
      content: "";
      display: block;
      padding-top: percentage(254/450);
    }
  }

  // Description
  .rtrQuestDesc {
    font-family: "Arial", sans-serif;
    text-align: left;
    color: #999;
    padding-top: 12px;

    @include desktop() {
      font-size: 16px;
    }

    @include mobile() {
      font-size: num-em(28);
      margin-top: num-em(20);
    }
  }

  // Question
  .rtrQuestionBlock {

  }

  // Answers
  .rtrQuestAnswers {
    display: flex;
    flex-wrap: wrap;

    button {
      display: block;
    }

    @include desktop() {
      margin: 0 -5px;

      button {
        margin: 2px 4px;
        flex: calc(50% - 8px) 0 1;
      }
    }

    @include mobile() {
      button {
        flex: 100%;
        margin: num-em(6) 0;

        &:nth-child {
          margin-bottom: 0;
        }
      }
    }
  }

  // Actions
  .rtrQuestActions {
    display: flex;
    flex-wrap: wrap;

    button {
      display: block;

      .rtrButton {
        margin: 0;
      }
    }

    .rtrQuestActionsFavorite {
      i {
        background: url(img/heart-empty.png) no-repeat center center;
        background-size: 100% auto;
      }
    }

    .selected .rtrQuestActionsFavorite {
      i {
        background-image: url(img/heart-full.png);
      }
    }

    @include desktop() {
      margin: 0 -5px;

      button {
        margin: 2px 4px;
        flex: calc(50% - 8px) 0 1;
      }
    }

    @include mobile() {
      button {
        flex: 100%;
        margin: num-em(6) 0;

        &:nth-child {
          margin-bottom: 0;
        }
      }
    }
  }

  // results
  .rtrScoreCircle {
    display: inline-block;
    border-radius: 50%;
    color: white;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 55%);

    @include desktop() {
      font-size: 24px;
      height: 250px;
      width: 250px;
    }

    @include mobile {
      width: num-em(420);
      height: num-em(420);
    }
  }

  #rtrScoreNumber {
    @include desktop() {
      font-size: 64px;
      margin-top: 10px;
    }

    @include mobile() {
      margin-top: num-em(10);
      font-size: num-em(90);
    }
  }

  // results popup
  .rtrScoreDonePopupBG {
    position: absolute;
    display: block;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: white, $alpha: 0.5);
    opacity: 1;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;

    &.rtrViewHide {
      opacity: 0;
    }
  }
  .rtrScoreDonePopup {
    position: relative;
    display: block;
    width: 300px;
    top: 110px;
    margin: 0 auto;
    padding: 20px 0;
    font-size: 20px;
    background-color: white;
    transition: top 0.3s;
    -webkit-transition: top 0.3s;
    -moz-transition: top 0.3s;

    &.rtrViewHide {
      top: -400px;
    }
  }
  .rtrScoreDonePopupMiddle {
    padding: 10px 20px;
    margin: 10px 0;
    font-size: 22px;
  }

  /**
   * Global components
   */
  //RTRCatIcon
  .rtrCatIconBig {
    //
  }

  /**
   * Page components
   */
  //RTRQuestStateForm
  .rtrQuestStateForm {
    position: relative;
    width: 100%;
    padding: 5px;
    border: 1px solid #D1D1D1;
    font-family: "ChangaOne", sans-serif;
    font-weight: normal;
    color: #4c5978;
    height: 2em;

    @include desktop() {
      font-size: 18px;

      option {
        padding: 5px;
      }
    }

    @include mobile() {
      font-size: 1em;
    }
  }
}

#rtrQuestResult {
  .rtrQuestBlock {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include desktop() {
      min-height: 500px;
      padding-bottom: 150px;
    }

    @include mobile() {
      padding-bottom: num-em(250);
    }
  }
}