@import 'utils';

#rtrHelp {
  .rtrContent {
    @include desktop() {
      background-image: url("img/sketch-left-1.png"), url("img/sketch-right-1.png");
      background-position: left 0, right 25px;
      background-size: 96px auto;
      background-repeat: no-repeat;
    }
  }

  .rtrHelpWrapper {
    box-sizing: border-box;
    background-color: #ededed;

    @include desktop() {
      width: 512px;
      margin: 0 auto;
      padding: 20px;
    }

    @include mobile() {
      padding: num-em(20) num-em(20) num-em(40) num-em(20);
    }
  }

  .rtrHelpBox {
    opacity: 1;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;

    &.rtrHelpBoxFade {
      opacity: 0;
    }
  }

  .rtrHelpActions {
    display: flex;
    justify-content: center;

    @include desktop() {
      margin-bottom: 20px;

      button {
        flex: 40% 0 1;
        margin: 0 4px;
      }
    }

    @include mobile() {
      margin-bottom: num-em(20);

      button {
        flex: 40% 0 1;
        margin: 0 num-em(8);
      }
    }
  }

  /**
   * Page components
   */
  // RTRHelpSlide
  .rtrHelpSlide {
    color: #999;

    em {
      color: #b3266b;
      font-style: normal;
      font-weight: normal;
    }

    & > img {
      width: 100%;
      height: auto;
    }

    h2 {
      margin-left: auto;
      margin-right: auto;
    }

    @include desktop() {
      min-height: 420px;

      h2 {
        font-size: 18px;
        margin-top: 10px;
        max-width: 375px;
      }
    }

    @include mobile() {
      min-height: num-em(640);

      h2 {
        font-size: num-em(36);
        max-width: num-em(600);
      }
    }
  }

  // RTRHelpDots
  .rtrHelpDots {
    span {
      background-color: #4c5978;
      border-radius: 50%;
      display: inline-block;
      width: 10px;
      height: 10px;
      margin: 0 3px;

      &.rtrDotOn {
        background-color: #f7a11a;
      }
    }
  }
}