@import 'utils';
@import 'colors-effects';

/****
FONTS
****/

/* background: url(img/torn-top-mobile.png) center bottom no-repeat;*/

@font-face {
  font-family: "ChangaOne";
  src: url("font/changaone-regular-webfont.eot");
  src: url("font/changaone-regular-webfont.eot?#iefix") format("embedded-opentype"),
    url("font/changaone-regular-webfont.woff2") format("woff2"),
    url("font/changaone-regular-webfont.woff") format("woff"),
    url("font/changaone-regular-webfont.ttf") format("truetype"),
    url("font/changaone-regular-webfont.svg#changa_oneregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
font-family: "Chewy";
src: url("font/chewy-webfont.eot");
src: url("font/chewy-webfont.eot?#iefix") format("embedded-opentype"),
  url("font/chewy-webfont.woff") format("woff"),
  url("font/chewy-webfont.ttf") format("truetype"),
  url("font/chewy-webfont.svg#chewyregular") format("svg");
font-weight: normal;
font-style: normal;
}

/* app root */
#rtrApp-root {
  &::before,
  &::after {
    content: '';
    display: block;
  }

  @include mobile() {
    &::before {
      padding-top: percentage(35 / 768);
      background: url(img/torn-top-mobile.png) center bottom no-repeat;
      background-size: 100%;
      margin-bottom: -1px;
    }

    &::after {
      padding-top: percentage(50 / 768);
      background: url(img/torn-bottom-mobile.png) center top no-repeat;
      background-size: 100%;
      margin-top: -1px;
    }
  }

  @include desktop() {
    max-width: 728px;
    margin: 0 auto;

    &::before {
      padding-top: 0;
      height: 21px;
      margin-bottom: -1px;
      background: url(img/torn-top-desktop.png) no-repeat center bottom;
      background-size: 100%;
    }

    &::after {
      padding-top: 0;
      height: 23px;
      margin-top: -1px;
      background: url(img/torn-bottom-desktop.png) no-repeat center bottom;
      background-size: 100%;
    }
  }
}

.rtrApp {
  position: relative;
  background-color: white;
  color: #4c5978;
  text-align: center;
  font-family: "ChangaOne", /*-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/ sans-serif;
  font-size: 12pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin: 0;
    font-weight: normal;
  }

  input, button {
    font-family: "ChangaOne", sans-serif;
  }

  *:focus {
    outline : none;
  }

  @include desktop() {
    font-size: 16px;
  }

  @include mobile() {
    /* Corner stone to mobile resizing */
    /* All child font sizing should be in ems on mobile */
    font-size: num-vw(36, 768); // ~30px at 640px wide
  }
}

/* app views with transition */
.rtrView {
  padding: 1px 0;
  opacity: 1;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;

  &.rtrViewHide {
    opacity: 0;
  }

  .rtrContent {
    position: relative;

    @include mobile() {
      margin: 0 num-em(20);
      min-height: num-em(500);
    }
  }

  @include desktop() {
    &::before,
    &::after {
      content: "";
      display: block;
      height: 10px;
      background: url(img/stitch-purple.png) repeat-x -3px center;
      background-size: 22px 10px;
    }

    &::before {
      margin: 0 15px 15px 15px;
    }

    &::after {
      margin: 15px 15px 0 15px;
    }

    .rtrContent {
      min-height: 500px;
    }
  }
}

.rtrDesktopHeader {
  display: none;
}
.rtrMobileHeader {
  h3 {
    display: flex;
    text-align: center;
    text-transform: uppercase;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    padding: 0 num-em(100);
    height: 100%;
  }

  @include desktop() {
    display: none;
  }

  @include mobile() {
    h3 {
      min-height: num-em(80);
    }

    margin-bottom: num-em(40);
  }
}

/* app buttons */
button {
  cursor: pointer;
}

.rtrButton {
  display: inline-block;
  color: white;
  background-color: #4c5978;
  -webkit-appearance: none;
  border: none;
  border-bottom: solid 1px #31394c;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;
  box-sizing: border-box;

  &.rtrButtonSideIcon i {
    border-bottom: solid 1px #31394c;
  }

  &:disabled {
    background-color: #bbb !important;
    cursor: default;
    border-bottom-color: #a3a3a3 !important;
  }

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    background-color : #31394c;
    border-bottom-color:#4c5978;
  }

  &.rtrButtonTeal {
    border-bottom-color: #498c77;
    background-color: #51a38a;

    &:hover:not(:disabled),
    &:active:not(:disabled),
    &:focus:not(:disabled) {
      background-color: #498c77;
      border-bottom-color: #51a38a;
    }
  }

  i.rtrIconNext {
    display: inline-block;
    line-height: 1.2em;
    width: 1em;
    height: 1.2em;
    vertical-align: text-top;
    margin-left: 0.75em;
    background: url(img/icon-next.png) no-repeat center center;
    background-size: contain;
  }

  @include desktop() {
    font-size: 18px;
    height: 42px;
    padding: 0 30px;
    border-bottom-width: 5px;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;

    &.rtrButtonSideIcon i {
      border-radius: 10px 0 0 10px;
      border-bottom-width: 5px;
      margin-bottom: -5px;
    }
  }

  @include mobile() {
    font-size: 1em;
    border-radius: num-em(14);
    height: num-em(100);
    padding: 0 num-em(80);
    border-bottom-width: num-em(8);
    margin-top: num-em(10);
    margin-bottom: num-em(10);

    &.rtrButtonSideIcon i {
      border-radius: num-em(14) 0 0 num-em(14);
      border-bottom-width: num-em(8);
      margin-bottom: num-em(-8);
    }
  }

  &.rtrCatButtonPink {
    background-color: $pink;
    border-color: $dark-pink;

    &:hover {
      border-color: $red;
      background-color: $dark-pink;
    }
  }

  &.rtrCatButtonRed {
    background-color: $red;
    border-color: $dark-red;

    &:hover {
      border-color: $red;
      background-color: $dark-red;
    }
  }

  &.rtrCatButtonGreen {
    background-color: $green;
    border-color: $dark-green;

    &:hover {
      border-color: $green;
      background-color: $dark-green;
    }
  }

  &.rtrCatButtonYellow {
    background-color: $yellow;
    border-color: $dark-yellow;

    &:hover {
      border-color: $yellow;
      background-color: $dark-yellow;
    }
  }

  &.rtrCatButtonOrange {
    background-color: $orange;
    border-color: $dark-orange;

    &:hover {
      border-color: $orange;
      background-color: $dark-orange;
    }
  }

  &.rtrCatButtonTeal {
    background-color: $teal;
    border-color: $dark-teal;

    &:hover {
      border-color: $teal;
      background-color: $dark-teal;
    }
  }

  &.rtrCatButtonBlue {
    background-color: $blue;
    border-color: $dark-blue;

    &:hover {
      border-color: $blue;
      background-color: $dark-blue;
    }
  }

  &.rtrCatButtonPurple {
    background-color: $purple;
    border-color: $dark-purple;

    &:hover {
      border-color: $purple;
      background-color: $dark-purple;
    }
  }
}

.rtrButtonX {
  padding: 0;
  margin: 0;
  background-image: url(img/close.png);
  background-repeat: no-repeat;
  background-position: center center;

  @include desktop() {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 0;
    right: 15px;
    background-size: 26px auto;
  }

  @include mobile() {
    width: num-em(88);
    height: num-em(88);
    position: absolute;
    top: 0;
    right: 0;
    background-size: 60% auto;
  }
}

.rtrButtonHelp {
  padding: 0;
  margin: 0;
  background-image: url(img/question-mark.png);
  background-repeat: no-repeat;
  background-position: center center;

  @include desktop() {
    width: 46px;
    height: 51px;
    position: absolute;
    top: 0;
    right: 15px;
    background-size: 20px auto;
  }

  @include mobile() {
    width: num-em(88);
    height: num-em(88);
    position: absolute;
    top: 0;
    right: 20px;
    background-size: 45% auto;
  }
}

.rtrButtonBack {
  background-image: url(img/back-arrow.png);
  background-repeat: no-repeat;
  background-position: 40% center;
  padding: 0;
  margin: 0;

  @include desktop() {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 0;
    left: 15px;
    background-size: 18px auto;
  }

  @include mobile() {
    width: num-em(88);
    height: num-em(88);
    position: absolute;
    top: 0;
    left: 0;
    background-size: 45% auto;
  }
}

.rtrButtonNull {
  appearance: none;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: inherit;
}

.rtrButtonSideIcon {
  padding: 0;
  display: flex;
  align-items: center;

  i {
    background-color: #FFF !important;
    border-bottom-color: #dddcdc !important;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -1px;
  }

  span {
    flex-grow: 1;
    flex-shrink: 1;
  }

  @include mobile() {
    i {
      width: num-em(80);

      img {
        width: num-em(60);
      }
    }
  }

  @include desktop() {
    i {
      width: 38px;

      img {
        width: 30px;
      }
    }
  }
}

.rtrButtonFavorite {
  background: #FFF url(img/heart-empty.png) center center no-repeat;
  background-size: 100% auto;

  &.selected {
    background-image: url(img/heart-full.png);
  }
}

.rtrButtonFade {
  opacity: 0.7;
}


/***************
DESKTOP + TABLET
***************/

@include desktop() {
  /* app views */
  .rtrDesktopHeader {
    display: initial;
  }
  .rtrMobileHeader {
    display: none;
  }
}

/************
BROWSER HACKS
************/

/* Firefox */
@-moz-document url-prefix() {
  .rtrScoreIcon {
    height: 100%;
  }
}