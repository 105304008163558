@import 'utils';

#rtrIntro {
  .rtrIntroIcons {
    display: flex;
    justify-content: center;
  }

  .debug {
    position: fixed;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  @include desktop() {
    .rtrContent {
      padding-left: 105px;
      padding-right: 105px;
      background-image: url("img/sketch-left-1.png"), url("img/sketch-right-1.png");
      background-position: left 0, right 25px;
      background-size: 96px auto;
      background-repeat: no-repeat;
    }

    h1 {
      font-size: 30px;
      margin-bottom: 5px;
    }

    .rtrIntroIcons {
      margin: 5px 0 15px 0;
    }
  }

  @include mobile() {
    h1 {
      font-size: num-em(36);
    }

    .rtrIntroIcons {
      margin-top: num-em(20);
      margin-bottom: 0;
    }
  }

  /**
   * Global components
   */
  // RTRLogo
  .rtrLogo {
    margin: 0 auto;

    @include desktop() {
      margin-bottom: 30px;
    }

    @include mobile() {
      width: num-em(560);
      margin-bottom: num-em(40);
      margin-top: num-em(30);
    }
  }

  // RTRTimer
  .rtrTimer {
    @include desktop() {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }

    @include mobile() {
      margin-top: num-em(30);
      margin-bottom: num-em(10);
    }
  }

  /**
   * Page Components
   */
  // RTRIntroNoLogin
  .rtrIntroNoLogin {
    @include mobile() {
      h1 {
        margin: num-em(60) auto num-em(40) auto;
        max-width: num-em(470);
      }
    }

    @include desktop() {
      h1 {
        margin: 40px auto 20px auto;
        max-width: 400px;
      }
    }
  }

  // RTRIntroIcon
  .rtrIntroIcon {
    margin-left: auto;
    margin-right: auto;

    img {
      display: block;
      width: 100%;
    }

    .selectArrow {
      width: 30px;
      margin: 3px auto;

      &.hidden {
        opacity: 0;
      }
    }

    @include desktop() {
      width: 118px;
    }

    @include mobile() {
      width: num-em(160);
    }
  }

  // RTRIntroTeamPicked
  .rtrIntroTeamPicked {
    .rtrTeamName {
      text-transform: uppercase;
    }

    @include desktop() {
      .rtrTeamName {
        margin-bottom: 15px;
      }
    }

    @include mobile() {
      .rtrTeamName {
        margin-bottom: num-em(30);
      }
    }
  }

  // RTRIntroPickTeam
  .rtrIntroPickTeam {
    @include mobile() {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}