@import 'utils';

.rtrLogo {
  img {
    width: 100%;
  }

  @include desktop() {
    max-width: 330px;
  }
}