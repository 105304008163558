@import 'utils';

#rtrCharts {
  @include desktop() {
    .rtrContent {
      background-image: url("img/sketch-left-1.png"), url("img/sketch-right-1.png");
      background-position: left 0, right 25px;
      background-size: 96px auto;
      background-repeat: no-repeat;
    }
  }

  #rtrMapChart, #rtrPieChart, #rtrBarChart {
    display: block;
    margin: 0 auto;

    @include desktop() {
      width: 600px;
      height: 400px;
    }

    @include mobile() {
      width: num-em(720);
      height: num-em(511);
    }
  }
}