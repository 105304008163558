@import 'utils';

.rtrScoreBlock {
  position: relative;
  display: flex;
  color: white;
  cursor: pointer;

  .rtrScoreIcon {
    background-color: white;
    border-radius: 14px 0 0 14px;
    border-bottom: solid 1px #dddcdc;
  }

  .rtrScoreData {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: center;
    flex-direction: column;
    border-bottom: solid 1px #FFF;

    .rtrScoreNumber {
      text-align: left;
      font-family: "Chewy", sans-serif;
      display: block;
      line-height: 1em;
    }

    .rtrScoreName {
      text-align: left;
      display: block;
      text-transform: uppercase;
    }
  }

  @include mobile() {
    margin: num-em(10) 0;
    height: num-em(160);

    .rtrScoreIcon {
      border-bottom-width: num-em(8);
      border-radius: num-em(14) 0 0 num-em(14);
      width: num-em(140);

      img {
        width: num-em(120);
        margin-top: 4%;
      }
    }

    .rtrScoreData {
      border-bottom-width: num-em(8);
      border-radius: 0 num-em(14) num-em(14) 0;
      padding: 0 num-em(20);

      .rtrScoreNumber {
        font-size: num-em(80);
      }
    }

    /* Note that rtrScoreBlockBig and small styling are the same on mobile */
  }

  @include desktop() {
    margin: 5px 0;
    height: 65px;

    .rtrScoreIcon {
      border-bottom-width: 5px;
      border-radius: 10px 0 0 10px;
      width: 60px;

      img {
        width: 50px;
        margin-top: 1%;
      }
    }

    .rtrScoreData {
      border-bottom-width: 5px;
      border-radius: 0 10px 10px 0;
      padding: 0 10px;

      .rtrScoreNumber {
        font-size: 27px;
      }
    }

    &.rtrScoreBlockBig {
      height: 80px;

      .rtrScoreIcon {
        width: 75px;

        img {
          width: 56px;
          margin-top: 6%;
        }
      }

      .rtrScoreData {
        .rtrScoreNumber {
          font-size: 36px;
        }
      }
    }
  }
}