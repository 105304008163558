@import 'utils';

.rtrTimer {
  display: flex;
  justify-content: center;
  text-align: center;

  .rtrTimerNumber {
    color: #bb3276;
    font-family: "Chewy", sans-serif;
    line-height: 0.8em;
  }

  .rtrTimerLabel {
    color: #4c5978;
    line-height: 1em;
  }

  @include mobile() {
    font-size: num-em(90);

    .rtrTimerSegment {
      min-width: num-em(100, 90);
      margin: 0 num-em(20, 90);
    }

    .rtrTimerLabel {
      font-size: num-em(32, 90);
    }
  }

  @include desktop() {
    font-size: 50px;

    .rtrTimerSegment {
      min-width: num-em(66, 50);
      margin: 0 5px;
    }

    .rtrTimerLabel {
      font-size: num-em(16, 50);
    }
  }
}