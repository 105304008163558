$browser-context: 36px;
@import '../node_modules/@curiousmedia/sass-numbers/sass-numbers';

/**
 * Desktop breakpoint
 */
@mixin desktop() {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

/**
 * Mobile breakpoint
 */
@mixin mobile() {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

/**
 * Capitalize
 */
@function capitalize($string) {
  @return to-upper-case(str-slice($string, 1, 1)) + str-slice($string, 2);
}