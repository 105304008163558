@import 'utils';

#rtrHome {
  // Disable purple lines
  &::before,
  &::after {
    display: none;
  }

  // Content
  .rtrContent {
    @include desktop() {
      background-image: url("img/sketch-left-3.png"), url("img/sketch-right-3.png"), url("img/home/bird-bg.png");
      background-position: left 100px, right 85px, 390px 98px;
      background-repeat: no-repeat;
      background-size: 79px auto, 79px auto, 57px auto;
    }
  }

  // Home header
  .rtrHomeHeader {
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

    @include mobile() {
      margin: 0 num-em(-20);
      background: url(img/home/header-bg-mobile.jpg) no-repeat center center;
      background-size: contain;

      &::before {
        content: "";
        display: block;
        padding-top: percentage(318 / 768);
      }

      .rtrButtonHelp {
        top: num-em(180);
      }
    }

    @include desktop() {
      height: 90px;
      background: url(img/home/header-bg-desktop.jpg) no-repeat center center;
      background-size: contain;

      .rtrButtonHelp {
        top: 20px;
      }
    }
  }

  // Home row
  .rtrHomeRow {
    position: relative;

    @include mobile() {
      h1 {
        margin: num-em(20) 0 num-em(10) 0;
      }

      .rtrHomeCol.right {
        &::before {
          content: "";
          display: block;
          padding-top: percentage(41/1388);
          background: url(img/stitches-purple-horizontal-mobile.png) no-repeat center center;
          background-size: 100% auto;
          margin-bottom: num-em(-30);
          margin-top: num-em(20);
        }
      }
    }

    @include desktop() {
      display: flex;

      &::before {
        content: "";
        display: block;
        width: 0;
        height: calc(100% - 20px);
        top: 10px;
        left: 338px;
        position: absolute;
        border-image-source: url(img/stitches-purple-vertical-border.png);
        border-image-slice: 44 20 44 20;
        border-image-width: 22px 10px 22px 10px;
        border-image-repeat: round;
        border-right: 32px solid;
      }

      h1 {
        margin: 10px 0 5px 0;
      }

      & > div {
        width: 50%;
        margin: 0 15px;
      }
    }
  }

  // Video
  .rtrVideo {
    color: white;
    background-color: black;
    border: none;
    box-shadow: none;

    @include mobile() {
      width: num-em(705);
      height: num-em(396);
    }

    &::before {
      content: "";
      display: block;
      padding-top: percentage(254/450);
    }

    @include desktop() {
      width: 334px;
      height: 188px;
    }
  }

  // Big question
  .rtrBigQuestion {
    display: flex;

    & > button {
      flex-grow: 1;
      flex-shrink: 1;
      width: 50%;

      margin-right: num-em(10);

      &:last-child {
        margin-right: 0;
      }
    }

    @include desktop() {
      .rtrButton {
        font-size: 14px;
      }
    }

    @include mobile() {
      .rtrButton {
        font-size: num-em(30);
      }
    }
  }

  // Team score
  .rtrTeamScore {
    width: 100%;

    @include desktop() {
      &::before {
        content: "";
        display: block;
        border-image-source: url(img/stitches-purple-horizontal-border.png);
        border-image-slice: 20 46 20 46;
        border-image-width: 10px 23px 10px 23px;
        border-image-repeat: round;
        border-top: 20px solid;
        margin: 5px 0 -5px 0;
      }
    }

    @include mobile() {
      &::before {
        content: "";
        display: block;
        padding-top: percentage(41/1388);
        background: url(img/stitches-purple-horizontal-mobile.png) no-repeat center center;
        background-size: 100% auto;
        margin-bottom: num-em(25);
        margin-top: num-em(20);
      }
    }
  }

  /**
   * Global components
   */
  // rtrTimer
  .rtrTimer {
    position: absolute;

    @include desktop() {
      top: 19px;
      right: 85px;
    }

    @include mobile() {
      top: num-em(70);
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  //rtrLogo
  .rtrLogo {
    position: absolute;

    @include desktop() {
      width: 330px;
      left: 20px;
      top: -5px;
    }

    @include mobile() {
      width: num-em(561);
      left: 50%;
      transform: translate(-50%, 0);
      top: num-em(-16);
    }
  }

  /**
   * Page components
   */
  // RTRHomeQuestList
  .rtrHomeQuestList {

    // Current
    .rtrHomeQuestListCurrent {
      background-color: #ededed;

      .rtrHomeQuestListSlot {
        &.empty {
          background-color: #bbbbbb;
          display: flex;
          align-items: center;
          justify-content: center;
          border-top: solid 1px #ababaa;
        }
      }

      @include desktop() {
        margin-bottom: 5px;
        padding: 0 5px 5px 5px;

        h2 {
          font-size: 18px;
          padding: 5px 0;
        }

        h3 {
          font-size: 16px;
        }

        .rtrHomeQuestListSlot {
          height: 37px;

          &.empty {
            border-top-width: 3px;
          }
        }
      }

      @include mobile() {
        margin-bottom: num-em(10);
        padding: 0 num-em(10) num-em(10) num-em(10);

        h2 {
          font-size: num-em(36);
          padding: num-em(10) 0;
        }

        h3 {
          font-size: num-em(36);
        }

        .rtrHomeQuestListSlot {
          height: num-em(90);

          &.empty {
            border-top-width: num-em(8);
          }
        }
      }
    }

    // Options
    .rtrHomeQuestListOptions {
      background-color: #ededed;

      @include desktop() {
        margin-bottom: 5px;
        padding: 0 5px 5px 5px;

        h2 {
          font-size: 18px;
          padding: 5px 0;
        }
      }

      @include mobile() {
        margin-bottom: num-em(10);
        padding: 0 num-em(10) num-em(10) num-em(10);

        h2 {
          font-size: num-em(36);
          padding: num-em(10) 0;
        }
      }
    }
  }

  // RTRHomeQuestListItem
  .rtrHomeQuestListItem {
    display: flex;
    width: 100%;

    &:last-child {
      margin-bottom: 0 !important;
    }

    .rtrHomeQuestListItemButton {
      text-align: left;
      flex-grow: 1;
      color: #FFF;
    }

    @include desktop() {
      margin-bottom: 5px;
    }

    @include mobile() {
      margin-bottom: num-em(10);
    }

    .rtrHomeQuestListItemInner {
      display: flex;

      span {
        align-self: center;
        flex-grow: 1;
      }

      i {
        background-color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        flex-grow: 0;
      }

      @include desktop() {
        height: 37px;

        span {
          padding: 6px;
          font-size: 14px;
          width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          box-sizing: border-box;
        }

        i {
          width: 37px;

          img {
            width: 30px;
          }
        }
      }

      @include mobile() {
        height: num-em(90);

        span {
          padding: num-em(20);
          font-size: num-em(36);
        }

        i {
          width: num-em(90);

          img {
            width: num-em(70);
          }
        }
      }
    }

    .rtrHomeQuestListItemFavorite {
      flex-grow: 0;
      flex-shrink: 0;
      background-color: #FFF;

      @include desktop() {
        width: 37px;
      }

      @include mobile() {
        width: num-em(90);
      }
    }
  }
}