@import 'utils';

#rtrStats {
  // Disable purple lines
  &::before,
  &::after {
    display: none;
  }

  .rtrContent {
    @include desktop() {
      overflow-x: hidden;
      background-image: url("img/sketch-left-2.png"), url("img/sketch-right-2.png");
      background-position: left 110px, right 128px;
      background-repeat: no-repeat;
      background-size: 118px auto;
    }
  }

  // Stats header
  .rtrStatsHeader {
    background: url(img/bg-jeans.jpg) center center;
    padding-top: 1px;
    padding-bottom: 1px;
    box-sizing: border-box;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25);

    &::before,
    &::after {
      display: block;
      content: "";
    }

    @include desktop() {
      &::before,
      &::after {
        width: 708px;
        margin: 2px auto;
        padding-top: percentage(21/1417);
        background: url(img/stitches-white-desktop.png) no-repeat center center;
        background-size: 100% auto;
      }

      .rtrStatsHeaderSub {
        padding: 0 65px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4px;

        & > * {
          margin: 2px 4px;
          flex: calc(50% - 8px) 1 1;
        }
      }

      .rtrButtonX {
        background-image: url(img/close-blue.png);
        background-color: #FFF;
        border-bottom-color: #e2e2e2;
        top: 16px;
        right: 10px;

        &:hover {
          border-bottom-color: #FFF;
          background-color: #e2e2e2;
        }
      }
    }

    @include mobile() {
      margin: 0 num-em(-20);

      &::before,
      &::after {
        padding-top: percentage(33/1536);
        margin: num-em(8) auto;
        background: url(img/stitches-white-mobile.png) no-repeat center center;
        background-size: 100% auto;
      }

      .rtrStatsHeaderSub {
        padding: 0 20px;
      }
    }
  }

  // Tabs
  .rtrButtonTab {
    display: none;

    @include desktop() {
      display: block;
      position: absolute;
      top: 250px;
      width: 78px;
      height: 200px;
      background-color: rgba(0, 0, 0, 0);
      border: none;
      margin: 0;
      padding: 0;
      cursor: pointer;

      &.rtrButtonTabLeft {
        left: 0;
        background: url(img/arrow-left.png) no-repeat center center;
        background-size: contain;
      }

      &.rtrButtonTabRight {
        right: 0;
        background: url(img/arrow-right.png) no-repeat center center;
        background-size: contain;
      }
    }
  }

  /**
   * Global components
   */

  /**
   * Page components
   */
  // StatsNews, StatsPlayers, StatsQuests
  #rtrStatsQuests,
  #rtrStatsPlayers,
  #rtrStatsNews {
    background-color: #ededed;
    margin-left: auto;
    margin-right: auto;

    .rtrStatsEntry {
      color: #FFF;
      background-color: #FFF;
      display: flex;
      text-transform: uppercase;
    }

    .rtrStatsEntryName {
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }

    .rtrStatsEntryValue {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    @include desktop() {
      width: 512px;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-bottom: 10px;

      h1 {
        font-size: 37px;
        margin-bottom: 5px;
      }

      .rtrStatsEntries {
        padding-bottom: 5px;

        &:last-child {
          padding-bottom: 0;
        }
      }

      .rtrStatsPlaceholder {
        padding: 0 10px;
      }

      .rtrStatsEntry {
        max-width: 450px;
        height: 37px;
        font-size: 16px;
        margin: 4px auto;
      }

      .rtrStatsEntryName {
        flex-basis: 65%;
        padding: 0 10px;
      }

      .rtrStatsEntryValue {
        flex-basis: 35%;
        padding: 0 10px;
      }

      &.rtrStatsTable {
        opacity: 1;
        transform: initial;
        transition: opacity 0.3s, transform 0.5s;
        -webkit-transition: opacity 0.3s, transform 0.5s;
        -moz-transition: opacity 0.3s, transform 0.5s;
      }

      &.rtrStatsTableHiding {
        opacity: 0;
      }

      &.rtrStatsTableLeft {
        transform: translateX(-1800px);
      }

      &.rtrStatsTableRight {
        transform: translateX(1800px);
      }

      &.rtrStatsHidden {
        display: none;
      }
    }

    @include mobile() {
      margin: num-em(40) 0;
      padding: 1px num-em(20) num-em(20) num-em(20);

      h1 {
        font-size: num-em(64);
        margin-bottom: num-em(10);
        margin-top: num-em(5);
      }

      .rtrStatsEntry {
        height: num-em(80);
        font-size: num-em(36);
        margin: 4px auto;
      }

      .rtrStatsEntryName {
        flex-basis: 60%;
        padding: 0 10px;
      }

      .rtrStatsEntryValue {
        flex-basis: 40%;
        padding: 0 10px;
      }

      .rtrStatsPlaceholder {
        font-size: num-em(40);
      }
    }
  }

  #rtrStatsQuests {
    @include mobile() {
      position: relative;
      margin-top: num-em(80);

      &::before {
        content: "";
        display: block;
        padding-top: percentage(41/1388);
        background: url(img/stitches-purple-horizontal-mobile.png) no-repeat center center;
        background-size: 100% auto;
        width: 100%;
        position: absolute;
        left: 0;
        top: num-em(-50);
      }
    }
  }
}