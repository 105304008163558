$pink: #b3266b;
$green: #8aaf47;
$yellow: #e5bb37;
$orange: #f58f28;
$blue: #29a5cf;
$teal: #51a38a;
$red: #b4222a;
$purple: #5c2169;
$gray: #666;

$dark-pink: #8f2156;
$dark-green: #6e8b3c;
$dark-yellow: #cea83d;
$dark-orange: #de8c0a;
$dark-blue: #298aab;
$dark-teal: #498c77;
$dark-red: #8a1a22;
$dark-purple: #260c2e;

$catColors: pink green yellow orange blue team red purple;

/* team colors */
.rtrTeamBG0 {
  background-color: #12910a;
}
.rtrTeamBG1 {
  background-color: #7f2fff;
}
.rtrTeamBG2 {
  background-color: #f9cc15;
}
.rtrTeamBG3 {
  background-color: #f15614;
}
.rtrTeamCol0 {
  color: #12910a;
}
.rtrTeamCol1 {
  color: #7f2fff;
}
.rtrTeamCol2 {
  color: #f9cc15;
}
.rtrTeamCol3 {
  color: #f15614;
}

/* category colors */
.rtrCatBGPink {
  background-color: $pink;
}
.rtrCatBGGreen {
  background-color: $green;
}
.rtrCatBGYellow {
  background-color: $yellow;
}
.rtrCatBGOrange {
  background-color: $orange;
}
.rtrCatBGBlue {
  background-color: $blue;
}
.rtrCatBGTeal {
  background-color: $teal;
}
.rtrCatBGRed {
  background-color: $red;
}
.rtrCatBGPurple {
  background-color: $purple;
}
.rtrCatBGGray {
  background-color: $gray;
}
.rtrCatColPink {
  color: $pink;
}
.rtrCatColGreen {
  color: $green;
}
.rtrCatColYellow {
  color: $yellow;
}
.rtrCatColOrange {
  color: $orange;
}
.rtrCatColBlue {
  color: $blue;
}
.rtrCatColTeal {
  color: $teal;
}
.rtrCatColRed {
  color: $red;
}
.rtrCatColPurple {
  color: $purple;
}
.rtrCatColGray {
  color: $gray;
}

/* shadow effects */
.rtrWhiteShadow {
  border-bottom-color: #e2e2e2 !important;
}
.rtrTeamShadow0 {
  border-bottom-color: #006b33 !important;
}
.rtrTeamShadow1 {
  border-bottom-color: #25397c !important;
}
.rtrTeamShadow2 {
  border-bottom-color: #d8ba3d !important;
}
.rtrTeamShadow3 {
  border-bottom-color: #bd401e !important;
}
.rtrCatShadowPink {
  border-bottom: solid 4px $dark-pink !important;
}
.rtrCatShadowGreen {
  border-bottom: solid 4px $dark-green !important;
}
.rtrCatShadowYellow {
  border-bottom: solid 4px $dark-yellow !important;
}
.rtrCatShadowOrange {
  border-bottom: solid 4px $dark-orange !important;
}
.rtrCatShadowBlue {
  border-bottom: solid 4px $dark-blue !important;
}
.rtrCatShadowTeal {
  border-bottom: solid 4px $dark-teal !important;
}
.rtrCatShadowRed {
  border-bottom: solid 4px $dark-red !important;
}
.rtrCatShadowPurple {
  border-bottom: solid 4px $dark-purple !important;
}
.rtrNoShadow {
  border-bottom: none;
}

/* inset effects */
.rtrLightGrayInset {
  border-top: solid 4pt #ccc;
}
.rtrGrayInset {
  border-top: solid 4pt #ababab;
}